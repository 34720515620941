body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ::-webkit-scrollbar {
  width: 15px;
  height: 5px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3); 
  box-shadow: inset 0 0 5px rgba(0,0,0,0.3); 
  border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  border-radius: 5px;
  border-radius: 10px;
 -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

